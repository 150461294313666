import { motion } from "framer-motion";
import PaymentModal from "../components/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Spin, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {  fetchPaymentDetails } from "../api/paymentApi";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { FaPlugCircleXmark } from "react-icons/fa6";
import { api } from "../config/apiConfig";
import Logo from '../assets/payscribe_blk.png';
import { useCallback } from "react";



/**
 * The main component of the payment link page.
 * This component displays the payment link form if the link is valid and active.
 * If the link is invalid or inactive, it displays an appropriate error message.
 * @return {JSX.Element} The JSX element representing the page content.
 */
const Index = () => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  const basicInfoValidator = yup.object().shape({
    fullName: yup.string().required("Please enter your full name"),
    email: yup
      .string()
      .email("Invalid email")
      .required("Please enter your email address"),
    phoneNumber: yup.string().required("Please enter your phone number"),
  });



  const { slug } = useParams();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [payerDetails, setPayerDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentView, setCurrentView] = useState("default");
  // const formattedAmount = null;

  const onSuccess = useCallback(() => {
    console.log("onSuccess called");
    setCurrentView("success");
    console.log("currentView:", currentView);
  }, [currentView]);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(basicInfoValidator),
  });

  const openNotificationWithIcon = (type, message, title) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  document.title = "Payment Link | " + (paymentDetails ? paymentDetails.title : "Error");

  useEffect(() => {
    const getPaymentDetails = async () => {
      if (!slug) {
        setLoading(false);
        setError("Invalid URL: Slug is missing.");
        return;
      }
      try {
        const data = await fetchPaymentDetails(slug);
        if (data && data.status === true) {
          setPaymentDetails(data.message.details);
        } else if (data.status_code === 400 || data.status_code === 404) {
          setError("The link is invalid or you cannot access this page.");
        } else {
          setError("This link is inactive or has expired.");
        }
      } catch (err) {
        console.error("Error fetching payment details:", err);
        openNotificationWithIcon(
          "error",
          "An error occurred while fetching payment details."
        );
      } finally {
        setLoading(false);
      }
    };

    getPaymentDetails();
  }, [slug]);

  const API_POST_URL = `https://api.payscribe.ng/api/v1/links/${slug}/payer`;

/**
 * Submits user information to the server and handles the response.
 * @param {Object} data - The user information to be submitted, containing the user's full name, phone number and email.
 * @return {Promise} A promise that resolves or rejects based on the server response.
 */
  const submitUserInfo = async (data) => {
    try {
      setLoadingData(true);

      const response = await api.post(API_POST_URL, {
        name: data.fullName,
        phone: data.phoneNumber,
        email: data.email,
      });
      console.log("Here is the payer details ->", payerDetails);


      if (response.status === 200 && response.data.status === true) {
        console.log("Response:", response.data);
        const payerDetails = response.data.message.details;
        console.log("Here is the payer details ->", payerDetails);

        setPayerDetails(payerDetails);

        toggleModalDisplay();
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );

      openNotificationWithIcon("error", "Network unstable.");
    } finally {
      setLoadingData(false);
    }
  };

  /**
   * Toggles the display state of the payment modal.
   * If the modal is currently open, this function will close it, and vice-versa.
   */

  const toggleModalDisplay = () => {
    setDisplayPaymentModal(!displayPaymentModal);
  };

  const formattedAmount = paymentDetails
    ? new Intl.NumberFormat("en-NG", {
        minimumFractionDigits: 0,
      }).format(paymentDetails.amount)
    : null;

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  if (paymentDetails && paymentDetails.status !== "active") {
    return (
      <div className="text-red-600 text-4xl flex justify-center items-center h-screen p-20">
        <div className="items-center">
          <div className="h-[10em] flex justify-center">
            <FaPlugCircleXmark className="text-[10em] text-red-200" />
          </div>
          <h2>This link has expired</h2>
          <div className="justify-center flex">
            <a
              href="https://www.payscribe.co"
              className="bg-primary text-white p-4 rounded my-10"
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    );
  } else if (paymentDetails) {
    return (
      <div className="grid md:grid-cols-2 grid-col-1">
        <div className="md:flex hidden items-center justify-center md:min-h-screen min-h-auto md:bg-gradient-to-r md:from-white md:to-white bg-gradient-to-r from-blue-500 to-indigo-600">
          <div className="m-3 bg-white backdrop-blur-lg shadow-2xl p-5 sm:w-[350px] w-full rounded-lg">
            {paymentDetails && (
              <div className="">
                <div className="mx-1 my-5 ">
                <img src={Logo} className="h-[3.5em] mx-1 px-2" alt="Logo" />

                </div>
                <h5 className="text-2xl text-start font-semibold mx-2 my-10">
                  New payment to {paymentDetails.business.name}
                </h5>
                <h4 className="mx-2 my-10 text-3xl text-start font-semibold">
                  {paymentDetails.title}
                </h4>
                <p className="mx-2 my-10 p-2 text-start text-xl">
                  {paymentDetails.description}
                </p>

                <div className="w-full bg-primary text-white flex justify-center p-5 rounded-xl font-medium text-2xl tracking-wider">
                  <h3>
                    <span className="uppercase">
                      {paymentDetails.currency}{" "}
                    </span>
                    {formattedAmount}
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
        <motion.div
          className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-500 to-indigo-600 md:p-4 p-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="sm:h-auto h-screen sm:w-auto w-screen">
            <div className="sm:w-md sm:max-w-[40em] max-w-full sm:h-auto h-screen sm:w-auto w-screen  bg-white shadow-lg rounded-lg p-5 md:p-8 md:-my-0 -my-0">
              <div className="md:hidden flex my-12 justify-center">
                {" "}
                <img src={Logo} className="h-[2.5em] mx-5 px-2" alt="Logo" />

              </div>

              <div className="md:hidden flex mx-5 my-10 justify-center space-x-10">
                <div>
                  <h4 className="mx-2 my-2 text-xl text-start font-semibold">
                    {paymentDetails.business.name}
                  </h4>
                  <p className="font-extrabold text-4xl m-3 text-start uppercase">
                    {paymentDetails.currency} {formattedAmount}
                  </p>
                  <h4 className="mx-2 my-2 text-xl text-start">
                    {paymentDetails.title}
                  </h4>
                </div>
              </div>
              <h2 className="text-3xl font-semibold text-gray-800 text-center mb-6">
                Who is paying?
              </h2>

              {success ? (
                <motion.div
                  className="bg-green-500 text-white text-center p-4 rounded-lg"
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  Your form was submitted successfully!
                </motion.div>
              ) : (
                <form
                  onSubmit={handleSubmit(submitUserInfo)}
                  className="space-y-2 w-full"
                >
                  <br />
                  {/* full Name */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Full Name
                    </label>
                    <Controller
                      name="fullName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter your full name"
                          className="mt-2 py-2"
                        />
                      )}
                    />
                    {errors.fullName && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.fullName.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Phone Number */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Phone Number
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="08004567890"
                          className="mt-2"
                        />
                      )}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.phoneNumber.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Email */}
                  <div className="md:my-0 my-5 md:mb-0 mb-1">
                    <label className="block sm:text-xl text-2xl font-medium text-gray-700">
                      Email
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      size="small"
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="Enter your email"
                          className="mt-2"
                        />
                      )}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-lg mt-1">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                  <br />
                  {/* Submit Button */}
                  <div>
                    <motion.button
                      type="submit"
                      disabled={loadingData}
                      className={`w-full md:py-5 py-7 sm:mt-2 mt-10 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-primary/90 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
                        loadingData ? "cursor-not-allowed opacity-50" : ""
                      }`}
                      whileHover={{ scale: loadingData ? 1 : 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                    >
                      {loadingData ? (
                        <Spin indicator={antIcon} />
                      ) : (
                        <span className="text-md">Submit</span>
                      )}
                    </motion.button>
                  </div>
                  <br />
                  <div className="text-center m-10 text-lg">
                    By continuing, you allow payscribe to charge you for this
                    payment
                  </div>
                  <div className="md:flex block justify-between mx-5 md:my-10 my-20 md:text-xl text-2xl">
                    <div className="md:my-2 my-10">
                      <a href="https://www.payscribe.co/privacy-policy" className="text-center" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <div className="md:my-2 my-10">
                      <a href="https://www.payscribe.co/terms-and-conditions" className="text-center">
                        Terms and condition
                      </a>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </motion.div>
        <div className="payment-modal-content">
          {/* Payment Modal Display */}
          {/* Passing the props into the Payment Modal */}
          <PaymentModal
            toggleModalDisplay={toggleModalDisplay}
            displayPaymentModal={displayPaymentModal}
            paymentDetails={paymentDetails}
            payerDetails={payerDetails}
            bankDetails={bankDetails}
            setBankDetails={setBankDetails}
            formattedAmount={formattedAmount}
            channel={"payment_link"}
            slug={slug}
          />
        </div>
      </div>
    );
  } else if (!paymentDetails) {
    return (
      <div className="text-red-600 text-4xl flex justify-center items-center h-screen p-20">
        <div>
          <div className="h-[10em] flex justify-center">
            <FaPlugCircleXmark className="text-[10em] text-red-200" />
          </div>
          <h2>The page you are trying to access does not exist</h2>
          <div className="justify-center flex">
            <a
              href="https://payscribe.co"
              className="bg-primary text-white p-4 rounded my-10"
            >
              Continue
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-red-600 text-4xl flex justify-center items-center h-screen">
        <div>
          <div className="h-[10em] flex justify-center">
            <FaPlugCircleXmark className="text-[10em] text-red-200" />
          </div>
          <h2 className="text-center">There is an issue connecting to the server</h2>
      <h2 className="text-center">Check your connection and ensure the Link you are trying to access is correct.</h2>
          <div className="justify-center flex">
            <a
              href="https://payscribe.co/contact"
              className="bg-primary text-white p-4 rounded my-10"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default Index;
