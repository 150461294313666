import React, { useState, useEffect, useRef } from "react";
import CustomSelect from "../CustomSelect";
import { api } from "../../config/apiConfig";
import { LuClipboardCheck, LuClipboardList } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../Styles/main.scss";
import { IoWarningOutline } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import AnimatedSequence from "../animatedloadersequence";
import { motion } from "framer-motion";
import Wait from "../../assets/wait.svg";
import Pending from "../Pending";

const testBankList = [
  { code: "000001", name: "STERLING BANK" },
  { code: "000002", name: "KEYSTONE BANK" },
];

const USSDPayment = ({
  bankList,
  amount,
  slug,
  amount_paid,
  paymentDetails,
  payerDetails,
  channel,
}) => {
  // console.log("Here", bankList);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [currentView, setCurrentView] = useState("default");
  const [isSourceActive, setIsSourceActive] = useState(false);
  const eventSourceRef = useRef(null);
  const timeoutRef = useRef(null);
  const [timeoutTriggered, setTimeoutTriggered] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const currentViewRef = useRef("default");
  const [currentView, setCurrentView] = useState(currentViewRef.current);

  // currentViewRef.current = 'default';
  const checkPaymentStatus = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (!acctDetails || !slug) {
      console.error("Missing account number or slug");
      return;
    }

    // closeEventSource();

    console.log("Initializing new EventSource...");
    eventSourceRef.current = new EventSource(
      `https://api.payscribe.ng/api/v1/links/${slug}/${acctDetails.account_number}/verify-payment`
    );

    setIsSourceActive(true);
    eventSourceRef.current.onmessage = (event) => {
      const paymentData = JSON.parse(event.data);
      setPaymentStatus(paymentData);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      console.log("Payment status:", paymentData.status);

      switch (paymentData.status) {
        case "pending":
          setCurrentView("loading");
          setTimeoutTriggered(true);

          break;

        case "unmatch":
          if (paymentData.total_amount_paid > amount_paid) {
            setCurrentView("balance");
            setTimeoutTriggered(false);
          } else {
            setCurrentView("loading");
          }
          break;

        case "closed":
          setCurrentView("balance");
          setTimeoutTriggered(false);

          closeEventSource();
          break;

        case "success":
          setCurrentView("success");
          closeEventSource();
          break;

        default:
          // Set timeout only if no other events occur
          if (!timeoutTriggered) {
            console.log("Setting timeout for waiting...");
            triggerWaitingTimeout();
          }
      }
    };

    eventSourceRef.current.onerror = () => {
      console.error("Error with EventSource");
      closeEventSource();
    };
  };

  useEffect(() => {
    return () => {
      closeEventSource(); // Cleanup on unmount
    };
  }, []);

  const closeEventSource = () => {
    if (eventSourceRef.current) {
      console.log("Closing EventSource...");
      switch(paymentStatus.status){
        case "pending":
      setCurrentView("waiting");
      break;
      case "unmatch":
        if(paymentStatus.total_amount_paid > amount){
          setCurrentView("balance");
          
          closeEventSource();
        }else{
          setCurrentView("balance");
        }break;
      case "success":
        setCurrentView("success");
        break;
        default:
          setCurrentView("waiting");


      }
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      setIsSourceActive(false);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const triggerWaitingTimeout = () => {
    if (
      paymentStatus?.status === "unmatch" &&
      paymentStatus?.total_amount_paid === amount_paid
    ) {
      setTimeoutTriggered(true);
    } else {
      setTimeoutTriggered(false);
    }
    timeoutRef.current = setTimeout(() => {
      console.log("Timeout triggered - switching to waiting view");
      setCurrentView("waiting");
      setTimeoutTriggered(false);
    }, 120000);
  };

  const [selectedValue, setSelectedValue] = useState(() => {
    const storedOption = localStorage.getItem("selectedOption");
    return storedOption ? JSON.parse(storedOption) : null;
  });
  const [acctNumber, setAcctNumber] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const [acctDetails, setAcctDetails] = useState(null);
  const [formError, setFormError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [setManualCopyMode] = useState(false);

  const handleChange = (selectedOption) => {
    console.log("Selected bank:", selectedOption);
    setSelectedBank(selectedOption);
    localStorage.setItem("selectedBank", JSON.stringify(selectedOption)); // Persist in localStorage
  };

  useEffect(() => {
    if (!selectedValue) {
      const storedOption = localStorage.getItem("selectedOption");
      if (storedOption) {
        setSelectedValue(JSON.parse(storedOption));
      }
    }
  }, [selectedValue]);

  const currencySymbols = {
    usd: "$",
    ngn: "₦",
  };

  const getCurrencySymbol = (currency) => {
    return currencySymbols[currency] || "#";
  };

  // const storedOption = localStorage.getItem("storedOption");

  useEffect(() => {
    const storedOption = localStorage.getItem("storedOption");
    const selectedOption = localStorage.getItem("selectedOption");

    if (storedOption) {
      const parsedStoredOption = JSON.parse(storedOption);
      setSelectedBank(parsedStoredOption);
    }

    if (selectedOption) {
      const parsedSelectedOption = JSON.parse(selectedOption);
      setSelectedValue(parsedSelectedOption);
    }
  }, []);

  const handleCopy = () => {
    if (acctDetails?.ussd_code) {
      if (
        navigator.clipboard &&
        typeof navigator.clipboard.writeText === "function"
      ) {
        // Try Clipboard API
        navigator.clipboard
          .writeText(acctDetails.ussd_code)
          .then(() => {
            setCopySuccess(true); // Show success message
            setTimeout(() => setCopySuccess(false), 4000);
          })
          .catch(() => {
            // If Clipboard API fails, try the fallback
            fallbackCopyTextToClipboard(acctDetails.ussd_code);
          });
      } else {
        // Clipboard API not supported, use fallback
        fallbackCopyTextToClipboard(acctDetails.ussd_code);
      }
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Position it off-screen
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    const successful = document.execCommand("copy");

    if (successful) {
      setCopySuccess(true); // Show success message
      setTimeout(() => setCopySuccess(false), 4000);
    } else {
      // If copy fails, inform the user
      alert(
        "Copy feature is not supported on your device. Please copy manually."
      );
      setManualCopyMode(true); // Enable manual copy mode
    }

    // Remove the <textarea>
    document.body.removeChild(textArea);
  };

  const validateForm = () => {
    const inputFields = document.querySelectorAll("input");
    let isValid = true;

    inputFields.forEach((input) => {
      if (!input.checkValidity()) {
        input.classList.add("text-red-500");
        isValid = false;
      } else {
        input.classList.remove("text-red-500");
      }
    });

    return isValid;
  };

  const startsWith = (prefix) => {
    return bankList.filter((value) => value.startsWith(prefix.toString()));
  };

  // Validate account number
  const validateAcct = async () => {
    setLoading(true);

    try {
      // Get request passing in the parameters
      const lookupUrl = `links/${slug}/tab?type=account_lookup&id=${payerDetails?.id}&channel=${channel}&bank=${selectedBank?.code}&account=${acctNumber}`;
      const response = await api.get(lookupUrl);

      console.log("Here is the response", response.description);

      if (response.status === 200) {
        setAcctDetails(response.data.message.details);
        currentViewRef.current = "code";
        setCurrentView(currentViewRef.current);
      } else if (response.status_code >= 400 && response.status_code <= 404) {
        setError(
          error.response.data.description ||
            "Check your account number and try again"
        );
      } else {
        setError(
          error.response.data.description ||
            "An error occurred. Please try again."
        );
        setCurrentView("error");
      }
    } catch (error) {
      setError("Check the account number and try again");
      setCurrentView("error");

      if (error.response) {
        console.error(error.response.data.description);
      } else if (error.request) {
        console.error("No response from the server");
      } else {
        console.error("Error setting up the request");
      }
    }

    setLoading(false);
  };

  console.log(error);

  if (loading)
    return (
      <div className="card-form-div animate-pulse flex justify-center items-center flex-col">
        <div className="mb-4 h-[2em] my-2 w-[100%] bg-gray-300 rounded"></div>
        <div className="my-8 h-[12em] w-[70%] bg-gray-300 rounded"></div>
        <div className="my-4 h-[3em] w-[100%] bg-gray-300 rounded"></div>

      </div>
    );

  return (
    <div className="card-form-div relative">
      <div className="flex justify-center flex-col">
        {currentView === "default" && (
          // <div className="flex justify-center items-center">
          //             <div className="w-[80%]">
          //         <select value={selectedValue} onChange={handleChange} className="p-4 w-full m-5">
          //           {options.map((option) => (
          //             <option key={option.value} value={option.value}>
          //               {option.label}
          //             </option>
          //           ))}
          //         </select>
          //         <p>You selected: {selectedValue}</p>
          //       </div>
          //         </div>
          <div className="mb-10 mt-10">
            <p className="text-red-500">{formError}</p>
            <CustomSelect
              options={bankList} // Bank list from API
              value={selectedBank} // Currently selected bank
              onChange={(selectedOption) => {
                console.log("Selected bank:", selectedOption);
                // alert("Selected bank:", selectedOption);

                setSelectedBank(selectedOption);
                localStorage.setItem(
                  "selectedBank",
                  JSON.stringify(selectedOption)
                );
              }}
              placeholder="Select a bank of your choice"
            />

            <div className="my-5">
              <form
                className="w-full mb-20 mt-5 "
                onSubmit={(e) => {
                  e.preventDefault();
                  // alert(selectedBank?.code);

                  if (!selectedBank) {
                    setFormError("Please select a bank.");
                    return;
                  }

                  if (!acctNumber) {
                    setFormError("Please enter a valid account number.");
                    return;
                  }

                  setFormError(""); // Clear errors
                  validateAcct();
                  currentViewRef.current = "code";
                  setCurrentView(currentViewRef.current);
                }}
              >
                <label className="my-2 text-2xl">
                  Enter the account number you will be paying with:
                </label>
                <input
                  name="acct_number"
                  type="text"
                  placeholder="Enter your account number"
                  className="w-full p-4 border-2 rounded-lg"
                  pattern="\d{10}"
                  maxLength={10}
                  required
                  value={acctNumber}
                  onChange={(e) => setAcctNumber(e.target.value)}
                />
                <div className="justify-center flex my-10">
                  <div className="text-red-600 border-2 border-red-400 p-4 mt-5 text-center rounded-xl flex justify-center items-center">
                    <IoWarningOutline />
                    <span className="mx-2">
                      Virtual accounts are not accepted
                    </span>
                  </div>
                </div>

                <div className="flex justify-center w-full">
                  <button
                    type="submit"
                    className={`bg-primary p-4 text-white w-[100%] rounded-xl ${
                      !acctNumber && !selectedValue
                        ? "cursor-not-allowed bg-primary/40"
                        : ""
                    }`}
                    disabled={!acctNumber && !selectedValue}
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {currentView === "code" && (
          <div className="flex justify-center items-center flex-col font-sans">
            {/* <div className="w-[80%] my-20 mx-10">
              <p className="my-10 text-2xl">
                <span className="font-semibold">
                  {acctDetails.account_name},{" "}
                </span>{" "}
                <span>dial</span>{" "}
              </p>
            </div> */}
            <p className="my-10 text-2xl font-semibold">
              <span>{acctDetails?.customer_name.split(" ")[0]},</span>{" "}
              <span>dial the code below to proceed with payment</span>
            </p>
            <div className="flex  justify-center">
              <div className="flex flex-col bg-slate-100 rounded-xl justify-center items-center md:p-5 p-10">
                <div>
                  <div className="md:my-0 my-10">
                    <h5 className="text-2xl m-5 text-center">
                      {acctDetails?.bank_name || <Skeleton width={100} />}
                    </h5>
                    <div className="account-bar mt-10">
                      <div className=" text-3xl flex items-center md:flex-cols flex-rows">
                        <h3>
                          {acctDetails?.ussd_code || <Skeleton width={120} />}
                        </h3>
                      </div>
                      <div className="flex items-center">
                        <button
                          onClick={handleCopy}
                          className={`copy-icon ${
                            copySuccess ? "copied" : ""
                          } text-4xl`}
                        >
                          {copySuccess ? (
                            <LuClipboardCheck />
                          ) : (
                            <LuClipboardList />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="leading-8 my-5 p-1 text-center">
                    Name on account: <br />
                    <b>
                      {acctDetails?.account_name || <Skeleton width={150} />}
                    </b>
                    <br />
                    {/* {channel === "payment_link" && (
                    <span>
                      You can only make a single exact payment to this account
                      number. <br />
                      Account expires in{" "}
                      <span className="font-semibold">
                        {remainingTime !== null ? (
                          formatTime(remainingTime)
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </span>{" "}
                      minutes.
                    </span>
                  )} */}
                  </p>
                  {copySuccess && (
                    <p className="copy-feedback text-xl text-center">
                      Code Copied!
                    </p>
                  )}
                  <div className="w-full lg:hidden justify-center flex">
                    <a
                      href={`tel:${acctDetails.ussd_code}`}
                      className="bg-green-800 text-white p-5 m-3 rounded-full text-3xl"
                    >
                      <FaPhone />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-center w-full mt-10">
              <button
                id="myBtn"
                //  onClick={checkPaymentStatus}
                onClick={() => {
                  if (!isSourceActive) {
                    checkPaymentStatus();
                  } else {
                    console.log(
                      "EventSource is already active. No new instance created."
                    );
                  }
                }}
                className="bg-primary p-4 text-white w-full rounded-xl font-medium"
              >
                I have paid
              </button>
            </div>
          </div>
        )}

        {currentView === "error" && error && (
          <div className="w-[100%] flex justify-center items-center">
            <div className=" my-20 mx-10 w-full">
              <div className="text-red-600 text-3xl text-center">{error}</div>

              <div className="mt-20 w-full">
                <button
                  className="bg-primary p-4 text-white w-[100%] rounded-xl font-semibold my-2"
                  onClick={() => validateAcct()}
                >
                  Retry
                </button>
                <button
                  className="bg-primary p-4 text-white w-[100%] rounded-xl font-semibold"
                  onClick={() => setCurrentView("default")}
                >
                  Go back
                </button>
              </div>
            </div>
          </div>
        )}

        {currentView === "loading" && (
          <React.Fragment>
            <div className="center md:h-auto h-screen block items-center justify-center my-10">
              <div>
                <Pending />
                <h3>
                  We are checking your payment. This may take a few minutes.
                </h3>
              </div>
              <div className="payment-div-body">
                <div className="my-4">
                  <h4>You will be notified when your payment is confirmed.</h4>
                </div>
                <div className=" flex justify-center w-[100%] mt-10">
                  <button
                    className="bg-primary p-4 w-[80%] text-white rounded-xl font-medium"
                    onClick={() => {
                      closeEventSource();
                      currentViewRef.current = "code";
                      setCurrentView(currentViewRef.current);
                    }}
                  >
                    View the USSD code
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        {currentView === "balance" && (
          <>
            <div className="flex flex-col items-center transform md:my-0 my-10 mx-5 justify-end text-2xl w-full  font-sans md:h-auto h-auto">
              <p className="text-center font-semibold text-amber-800 md:mb-10 my-10">
                Payment Incomplete!
              </p>
              <div className="mb-16 flex justify-center border border-dashed border-primary/50 rounded-xl p-8 w-full">
                <div className="justify-self-center flex justify-between w-full  flex-col p-3">
                  <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                    <span className="w-full">Total amount paid:</span>{" "}
                    <span className="text-primary tracking-wide flex justify-center">
                      {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                      {paymentStatus.total_amount_paid}
                    </span>
                  </div>
                  <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                    <span className="w-full">Amount paid now:</span>{" "}
                    <span className="text-primary tracking-wide flex justify-center">
                      {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                      {paymentStatus.amount}
                    </span>
                  </div>
                  <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                    <span className="w-full">Amount to be paid:</span>{" "}
                    <span className="text-primary tracking-wide flex justify-center">
                      {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                      {amount}
                    </span>
                  </div>
                  <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                    <span className="w-full">Amount remaining: </span>
                    <span className="text-primary tracking-wide flex justify-center">
                      {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                      {amount - paymentStatus.total_amount_paid}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="bg-primary p-4 text-white w-full rounded-xl font-medium"
                  onClick={() => {
                    setCurrentView("default");
                    closeEventSource();
                  }}
                >
                  View account details
                </button>
              </div>
            </div>
          </>
        )}

        {currentView === "success" && (
          <React.Fragment>
            <div className="flex justify-start flex-col items-center transform md:-translate-y-3 translate-y-[6em]  font-sans md:h-auto h-screen">
              <AnimatedSequence className="md:scale-[40%] scale-[60%]" />
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 8, duration: 1 }}
                className="text-3xl text-center font-semibold flex justify-center"
              >
                Payment Successful
              </motion.span>

              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 10, duration: 1 }}
                // Redirect on animation complete, based on the channel
                onAnimationComplete={() => {
                  setTimeout(() => {
                    window.location.href = `/${
                      channel === "payment_link" ? "pay" : "inv"
                    }/${slug}`;
                  }, 10000);
                }}
                className="text-2xl text-center font-semibold flex justify-center my-5"
              >
                You will be redirected away in a few seconds...
              </motion.span>
            </div>
          </React.Fragment>
        )}

        {currentView === "waiting" && (
          <>
            <div className="relative">
              <div className="absolute flex items-center justify-start flex-col bottom-1/4">
                <div className="z-10 text-center text-2xl font-semibold h-full">
                  You do not have to wait for so long, you will be notified when
                  the payment has been made...
                </div>
                <div className="button-cover z-10">
                  <button
                    id="myBtn"
                    onClick={() => {
                      console.log("Keep waiting button clicked");

                      // Clear any existing timeout
                      if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                        timeoutRef.current = null;
                      }

                      // Change the view to "loading"
                      setCurrentView("loading");

                      // Re-initiate the EventSource if inactive
                      if (!isSourceActive) {
                        checkPaymentStatus();
                      } else {
                        console.log(
                          "EventSource is already active. No new instance created."
                        );
                      }
                    }}
                    className="bg-primary p-4 text-white w-full rounded-xl font-semibold"
                  >
                    Keep waiting
                  </button>
                </div>
              </div>

              <div className=" top-0 left-0 opacity-[15%] ">
                <img src={Wait} className="" alt="" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default USSDPayment;
