import React from 'react';
import { motion } from 'framer-motion';

const Pending = () => {
  const circleVariants = {
    animate: {
      scale: [1, 1.4, 1],
      opacity: [1, 0.5, 1],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  const ringVariants = {
    animate: {
      rotate: [360, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      },
    },
  };

  return (
    <div className="flex items-center justify-center mb-24">
      <motion.div
        className="relative flex items-center justify-center w-24 h-24"
        variants={ringVariants}
        animate="animate"
      >
        <div className="absolute inset-0 border-4 border-gray-600 rounded-full border-t-[#274B85]" />
        <div className="absolute inset-0 border-4 border-gray-600 rounded-full border-b-[#274B85]" />
        
        {[...Array(3)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute w-4 h-4 bg-[#274B85] rounded-full"
            style={{
              top: index === 0 ? '10%' : 'auto',
              bottom: index === 1 ? '10%' : 'auto',
              left: index === 2 ? '50%' : index === 0 ? '50%' : 'auto',
              right: index === 1 ? '60%' : 'auto',
              transform: 'translate(-50%, -50%)',
            }}
            variants={circleVariants}
            animate="animate"
          />
        ))}
      </motion.div>
    </div>
  );
};

export default Pending;
