import React, { useState } from "react"

import { Checkbox } from "antd";

import _123 from "../../assets/images/123.svg";
import { Link, useNavigate } from "react-router-dom";

import CopyImg from "../../assets/images/copy.svg";
import Flag from "../../assets/images/flag.svg";

import { Controller, useForm } from "react-hook-form";

import { Input, Modal } from "antd";

import { TextField } from "@mui/material";

import ArrowBack from "../../assets/images/arrow-back.svg";
import CheckmarkCircle from "../../assets/images/checkmark-circle.svg";

const TransactionStatus = () => {

    const { handleSubmit, control } = useForm({});
    const [currentView, setCurrentView] = useState("verification");

    const toggleModalDisplay = () => {

    }
    return (
        <React.Fragment>
            <div className="payment-div-body">
                <div className="payment-sect-summary">
                    <img src={CheckmarkCircle} alt="checkmark-circle" className="circle-checkmark" />
                    <div className="mt_5">
                        <h4>Transaction Successful</h4>
                        <div className="align-center">
                            <p className="body-text">Thank you for your purchase. we have sent the reciept to your
                                email.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="button-cover">
                    <button id="myBtn" onClick={toggleModalDisplay} className="text-white bg-primary p-4 rounded-xl w-full my-4 ">Share Receipt</button>
                </div>
                <div className="button-cove">
                    <div className="flex-div">
                        <Link to="/" className="btn btn-blank flex-div">
                            <img
                                src={ArrowBack} alt="times icon" />
                            Go Back</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default TransactionStatus;