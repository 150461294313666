import React, { useState } from "react";
import { motion } from "framer-motion";
import { Spin } from "antd";
import OtpInput from "react-otp-input";
import { LoadingOutlined } from "@ant-design/icons";
import { api } from "../../config/apiConfig";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const EmailVerification = ({ email, onComplete, slug, channel }) => {
  const PAYSCRIBE_URL = `https://api.payscribe.ng/api/v1/links/${slug}/authenticate-payscribe`;


  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrorm] = useState(null);
  const [errorsm, setErrors] = useState({});


  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^[0-9]+$/, "OTP must contain only numbers")
      .length(5, 'OTP must be exactly 5 digits')
      .required('OTP is required'),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleVerifySubmit = async (data) => {
    try {
      const response = await payscribeEmailAuth(data);
      if (response.status === 200) {
        onComplete(); // Call onComplete if payscribeEmailAuth completes with a 200 status code
      } else {
        console.error("Error:", response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const payscribeEmailAuth = async (credentials) => {
    try {
      setIsLoading(true);

      const response = await api.post(PAYSCRIBE_URL, {
        otp: credentials.otp,
        channel: channel
      });
      if (response.status === 200) {
        console.log("Response:", response.data);
        onComplete();
      } else {
        throw new Error("Unexpected response status");
      }
    }catch (error) {
      const errorMessage =
      error.response && error.response.data.description
        ? error.response.data.description === "The user can not be found"
          ? "Email or Payscribe Tag is invalid"
          : error.response.data.description
        : error.message;
    console.log(error.response.data.description);
      
  // Set error for both fields if you want the message to appear at form level
  setError("otp", { message: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  return (
    <React.Fragment>
      <div className="center">
        <h3>Email Verification</h3>
        <p>
          Please enter the verification code that was sent to your email address{" "}
          {email}.
        </p>
        <p>The code will expire in 15 minutes.</p>
      </div>
      <div className="payment-div-body">
        <form onSubmit={handleSubmit(handleVerifySubmit)}>
          <div className="form-group">
            <label style={{ display: "block" }}>Enter OTP</label>
            <div className="otp-box">
      <Controller
        control={control}
        name="otp"
        render={({ field }) => (
          <OtpInput
            value={field.value}
            onChange={field.onChange}
            numInputs={5}
            isInputNum
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} type="password" className="text-black h-10 md:h-8"/>}
          />
        )}
      />
      {errors.otp && <p className=" text-red-500">{errors.otp.message}</p>}
    </div>
          </div>
          <motion.button
            type="submit"
            disabled={isLoading}
            className={`w-full py-5 my-10 text-2xl rounded-md bg-primary text-white font-semibold hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 ${
              isLoading ? "cursor-not-allowed opacity-50" : ""
            }`}
            whileHover={{ scale: isLoading ? 1 : 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.2 }}
          >
            {isLoading ? (
              <Spin indicator={antIcon} />
            ) : (
              <span className="text-md">Verify code</span>
            )}
          </motion.button>
        </form>
      </div>
    </React.Fragment>
  );
};
export default EmailVerification;
