import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.querySelector("#root"));

const AppIndex = () => {
    return (
        <App />
    )
}

root.render(<AppIndex />)