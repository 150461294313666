import React, { useState } from "react";
import { Modal as AntdModal } from "antd";
import Loader from "./Loader/Loader";
import BankDetails from "./pages/bank";
import CardPayment from "./pages/card";
import USSDPayment from "./pages/ussd";
import PayscribeCard from "./pages/payscribe";
import { api } from "../config/apiConfig";
import { MdOutlineCancel } from "react-icons/md";
import { fetchBankDetails, fetchBanks } from "../api/paymentApi";
import Logo from '../assets/payscribe_blk.png';
import BankImg from "../assets/images/bank.svg";
import BankWhiteImg from "../assets/images/bank-white.svg";
import CardWhiteImg from "../assets/images/card-2-svgrepo-com(1).svg";
import CardImg from "../assets/images/card-2-svgrepo-com.svg";
import HashImg from "../assets/images/hash-svgrepo-com.svg";
import HashWhiteImg from "../assets/images/hash-white.svg";
import PayscribeImg from "../assets/images/payscribe.svg";
import PayscribeWhiteImg from "../assets/images/payscribe-white.svg";
import Dev from "../assets/images/logo512.png";
import CancelIcon from "../assets/images/x.svg";
import ArrowLeft from "../assets/images/arrow-back.svg";
import { FaCaretLeft } from "react-icons/fa";

// type Props = {
//     toggleModalDisplay: () => void;
//     displayPaymentModal: boolean;
//     slug: any;
//     paymentDetails: any;
//     payerDetails: any;
//     bankDetails: any;
//     setBankDetails: (bankDetails: any) => void;
//     formattedAmount: number;
// }

/**
 * A modal component that displays payment options and handles payment
 * @param {Object} props Component props
 * @param {boolean} props.displayPaymentModal Whether to display the modal
 * @param {Object} props.paymentDetails Payment details
 * @param {number} props.balance Balance of the payer
 * @param {Object} props.payerDetails Payer details
 * @param {Array} props.bankDetails Bank details
 * @param {Function} props.setBankDetails Set bank details
 * @param {string} props.slug Link slug
 * @param {string} props.channel Payment channel
 * @returns {ReactElement} The modal component
 */
const PaymentModal = ({
  toggleModalDisplay,
  displayPaymentModal,
  paymentDetails,
  balance,
  payerDetails,
  bankDetails,
  setBankDetails,
  slug,
  channel
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentDisplay, setCurrentDisplay] = useState("payscribe");
  const [currentMobileOption, setCurrentMobileOption] = useState(1);
  const [currentMobileDisplay, setCurrentMobileDisplay] = useState("default");
  const [bankList, setBankList] = useState([]);
  const [currentView, setCurrentView] = useState("default");

/**
 * Returns a display name for a given payment method.
 * 
 * @param {string} method - The payment method.
 * @returns {string} The display name of the payment method.
 */
  const getPaymentDisplayName = (method) => {
    switch (method) {
      case "bank_transfer":
        return "Bank";
      case "ussd":
        return "USSD";
      case "cards":
        return "Card";
      default:
        return method.charAt(0).toUpperCase() + method.slice(1);
    }
  };


  /**
   * Returns an icon for a given payment method based on the method and if it is active or not.
   * 
   * @param {string} method - The payment method.
   * @param {boolean} isActive - If the payment method is the currently active method.
   * @returns {string} The icon of the payment method.
   */
  const getIcon = (method, isActive) => {
    switch (method) {
      case "bank_transfer":
        return isActive ? BankWhiteImg : BankImg;
      case "ussd":
        return isActive ? HashWhiteImg : HashImg;
      case "payscribe":
        return isActive ? PayscribeWhiteImg : PayscribeImg;
      case "cards":
        return isActive ? CardWhiteImg : CardImg;
      default:
        return null;
    }
  };

  const formattedAmount = paymentDetails
    ? new Intl.NumberFormat("en-NG", {
        minimumFractionDigits: 0,
      }).format(channel === "invoice" ? balance : paymentDetails.amount)
    : null;

    // console.log('i am vale ou bank ' + bankList);
  return (
    <AntdModal
      footer={null}
      onCancel={toggleModalDisplay}
      open={displayPaymentModal}
      className="sm:top-20 top-0 font-sans"
    >
      <div className="payment-modal-showcase">
        <div className="payment-modal-disp ">
          <div className="payment-options-list">
            <div className="payment-option-list-header">
              <h5>Payment Options</h5>
            </div>
            <div>
              <ul>
                {paymentDetails?.payments?.map((paymentMethod) => {
                  let paymentDisplayName;
                  switch (paymentMethod) {
                    case "bank_transfer":
                      paymentDisplayName = "Bank";
                      break;
                    case "ussd":
                      paymentDisplayName = "USSD";
                      break;
                    case "cards":
                      paymentDisplayName = "Card";
                      break;
                    default:
                      paymentDisplayName =
                        paymentMethod.charAt(0).toUpperCase() +
                        paymentMethod.slice(1);
                  }

                  const getIcon = (method, isActive) => {
                    switch (method) {
                      case "bank_transfer":
                        return isActive ? BankWhiteImg : BankImg;
                      case "ussd":
                        return isActive ? HashWhiteImg : HashImg;
                      case "payscribe":
                        return isActive ? PayscribeWhiteImg : PayscribeImg;
                      case "cards":
                        return isActive ? CardWhiteImg : CardImg;
                      default:
                        return undefined;
                    }
                  };

                  return (
                    <li
                      key={paymentMethod}
                      onClick={async () => {
                        try {
                          setError(null); // Clear previous errors
                          setCurrentDisplay(paymentMethod); //
                          // Validate the required values
                          if (!slug || !paymentMethod || !payerDetails?.id) {
                            throw new Error(
                              "Missing required information to log payment method"
                            );
                          }
                          console.log("Here is the current channel" + channel);

                          // Log the payment method
                          const logUrl = `links/${slug}/tab?type=${paymentMethod}&id=${payerDetails?.id}&channel=${channel}`;
                          const logResponse = await api.get(logUrl);
                          

                          if (logResponse.status !== 200) {
                            throw new Error("Failed to log payment method");
                          }

                          if (paymentMethod === "bank_transfer") {
                            const data = await fetchBankDetails(
                              slug,
                              paymentMethod,
                              payerDetails?.id,
                              channel
                            );

                            if (data && data.status === true) {
                              setBankDetails(data.message.details);
                            } else if (
                              data.status_code === 400 ||
                              data.status_code === 404
                            ) {
                              setError(
                                "The link is invalid or you cannot access this page."
                              );
                            } else if (
                              data.status_code === 400 ||
                              data.status_code === 404
                            ) {
                              setError(
                                "The link is invalid or you cannot access this page."
                              );
                            } else {
                              setError("This link is inactive or has expired.");
                            }
                          }
                          if (paymentMethod === "ussd") {
                            const data = await fetchBanks(
                              slug,
                              paymentMethod,
                              payerDetails?.id,
                              channel
                            );
                            if (data && data.status === true) {
                              setBankList(data.message.details);
                              // console.log( {data});
                            } else if (
                              data.status_code === 400 ||
                              data.status_code === 404
                            ) {
                              setError(
                                "The link is invalid or you cannot access this page."
                              );
                            } else if (
                              data.status_code === 400 ||
                              data.status_code === 404 
                            ) {
                              setError(
                                "The link is invalid or you cannot access this page."
                              );
                            } else {
                              setError("This link is inactive or has expired.");
                            }
                            
                          }
                        } catch (error) {
                          setError(`${error.message}`);
                        } finally {
                          setLoading(false); // Stop loader
                        }
                      }}
                      className={`single-payment-link w-full ${
                        currentDisplay === paymentMethod ? "active " : ""
                      }`}
                    >
                      <div className="flex-div">
                        <img
                          src={getIcon(
                            paymentMethod,
                            currentDisplay === paymentMethod
                          )}
                          alt={paymentMethod ?? ""}
                          className={`big w-[20px] h-[24px]`}
                        />
                        {paymentDisplayName}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* Display selected payment method details */}
          <div className="sm:rounded-xl rounded-none">
            <div className="top-modal">
              <div className="grid-flex">
                <div>
                  <div className="top-company-modal-logo p-1">
                  <button href="https://wa.me/+2349024328949" onClick={(e) => {
                    e.preventDefault();
                    window.open("https://wa.me/+2349024328949", "_blank");
                  }} className='z-[999] absolute top-[1.3em] mx-auto md:opacity-[40%] opacity-[30%] md:w-12 md:h-12 h-[0.3em] cursor-none w-[0.3em]' rel="noreferrer">
          <img
            src={Dev}
            className=""
            alt="balabluu"
          /></button>
                  <img src={Logo} className="h-[5.5em] mx-1 px-2" alt="Logo" />
                  </div>
                </div>
                {payerDetails ? (
                  <div className="p-5">
                    <p>{payerDetails.email}</p>
                    <p>
                      Pay{" "}
                      <b>
                        <span className="currency uppercase">
                          {paymentDetails.currency}{" "}
                        </span>
                        {formattedAmount}
                      </b>
                    </p>
                  </div>
                ) : (
                  <Loader/>
                )}
              </div>
            </div>

            <div className="desktop h-full w-full relative">
              {loading ? (
                <div className="loading-message">
                  <Loader />
                </div>
              ) : error ? (
                <div className="error-message flex items-center flex-col">
                  <p>{error}</p>
                  <button onClick={() => setError(null)} className="flex justify-center items-center text-white bg-primary px-5 py-2 my-10">Retry</button>
                </div>
              ) : (
                <>
                  {currentDisplay === "payscribe" ? (
                    <div className="">
                      <div>
                        {paymentDetails && payerDetails ? (
                          <PayscribeCard
                            amount={formattedAmount}
                            slug={slug}
                            payerId={payerDetails.id}
                            channel={channel}
                          />
                        ) : (
                          <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                        )}
                      </div>{" "}
                    </div>
                  ) : currentDisplay === "ussd" ? (
                    <div className="">
                      <USSDPayment
                       bankList={bankList} 
                       amount={formattedAmount}
                       slug={slug}
                       payerDetails={payerDetails}
                       channel={channel}
                       paymentDetails={paymentDetails}
                       amount_paid={channel === "invoice" ? paymentDetails.amount_paid : null}
                       
                       />{" "}
                    </div>
                  ) : currentDisplay === "cards" ? (
                    <div className="">
                      <CardPayment
                        payer={payerDetails}
                        amount={formattedAmount}
                        payment={paymentDetails}
                        slug={slug}
                      />{" "}
                    </div>
                  ) : (
                    <div className="">
                      {bankDetails ? (
                        <BankDetails
                          bankDetails={bankDetails}
                          amount={paymentDetails.amount}
                          expiryAt={bankDetails.expiry_at}
                          payerId={payerDetails.id}
                          slug={slug}
                          onSuccess={() => setCurrentView("success")} // Pass the setCurrentDisplay function as a prop
                          channel={channel}
                          balance={balance}
                          amount_paid={channel === "invoice" ? paymentDetails.amount_paid : null}
                        />
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {/* Mobile Modal View */}
            <div className="block w-full sm:hidden mobile-view fixed overflow-hidden sm:h-auto h-screen p-5 ">
              {currentMobileDisplay === "default" ? (
                <div className="card-form-div">
                  <div
                    onClick={toggleModalDisplay}
                    className="absolute top-5 right-10 text-black modal-cancel"
                  >
                    <MdOutlineCancel className="text-5xl" />
                  </div>
                  <div className="center">
                    <h3>Please select your preferred mode of payment</h3>
                  </div>
                  <div className="payment-method w-full ">
                    {paymentDetails?.payments?.map((method, index) => (
                      <div
                        key={index}
                        onClick={async () => {
                          try {
                            setError(null); // Clear previous errors
                            setCurrentMobileOption(index + 1);
                            setCurrentDisplay(method); // Start loader

                            // Validate the required values before logging
                            if (!slug || !method || !payerDetails?.id) {
                              throw new Error(
                                "Missing required information to log payment method"
                              );
                            }

                            // Log the payment method via API
                            const logUrl = `links/${slug}/tab?type=${method}&id=${payerDetails?.id}&channel=${channel}`;
                          const logResponse = await api.get(logUrl);

                          if (logResponse.status !== 200) {
                            throw new Error("Failed to log payment method");
                          }


                            // Fetch bank details if "bank_transfer" is selected
                            if (method === "bank_transfer") {
                              const data = await fetchBankDetails(
                                slug,
                                method,
                                payerDetails?.id,
                                channel
                              );

                              if (data?.status === true) {
                                setBankDetails(data.message.details);
                                // console.log(
                                //   "Bank details fetched:",
                                //   data.message.details
                                // );
                              } else if (
                                data?.status_code === 400 ||
                                data?.status_code === 404
                              ) {
                                setError(
                                  "The link is invalid or you cannot access this page."
                                );
                              } else {
                                setError(
                                  "This link is inactive or has expired."
                                );
                              }
                            }
                            if (method === "ussd") {
                              const data = await fetchBanks(
                                slug,
                                method,
                                payerDetails?.id,
                                channel
                              );
                              if (data && data.status === true) {
                                setBankList(data.message.details);
                                // console.log( {data});
                              } else if (
                                data.status_code === 400 ||
                                data.status_code === 404
                              ) {
                                setError(
                                  "The link is invalid or you cannot access this page."
                                );
                              } else if (
                                data.status_code === 400 ||
                                data.status_code === 404 
                              ) {
                                setError(
                                  "The link is invalid or you cannot access this page."
                                );
                              } else {
                                setError("This link is inactive or has expired.");
                              }
                              
                            }
                          } catch (error) {
                            setError(`Error: ${error.message}`);
                          } finally {
                            setLoading(false); // Stop loader
                          }
                        }}
                        className={`single-payment-method my-10 ${
                          currentMobileOption === index + 1
                            ? "active bg-primary text-white"
                            : ""
                        }`}
                      >
                        <div className="grid-flex w-full">
                          <div>
                            <img
                              src={
                                getIcon(
                                  method,
                                  currentMobileOption === index + 1
                                ) ?? ""
                              }
                              alt={method}
                              className={ "w-10 h-10"}
                            />
                          </div>
                          <h5 className="font-medium text-3xl">
                            {getPaymentDisplayName(method)}
                          </h5>
                        </div>
                      </div>
                    ))}
                    <br />
                    <button
                      className="w-full p-5 rounded-xl text-2xl bg-primary text-white font-semibold my-10"
                      onClick={() => setCurrentMobileDisplay("payment")}
                    >
                      Continue
                    </button>
                  </div>{" "}
                </div>
              ) : (
                <div className="external">
                  <div
                    className="mt_10 flex my-10 items-center"
                    onClick={() => setCurrentMobileDisplay("default")}
                  >
                   <FaCaretLeft className="text-4xl"/>{" "}
                    <span className="font-semibold m-1">
                      Want to use another payment method?
                    </span>
                  </div>
                  {currentDisplay === "payscribe" ? (
                    <div>
                      {paymentDetails ? (
                        <PayscribeCard
                          amount={formattedAmount}
                          payerId={payerDetails.id}
                          slug={slug}
                          channel={channel}
                        />
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  ) : currentDisplay === "bank_transfer" ? (
                    <div>
                      {bankDetails ? (
                         <BankDetails
                         bankDetails={bankDetails}
                         amount={paymentDetails.amount}
                         expiryAt={bankDetails.expiry_at}
                         payerId={payerDetails.id}
                         slug={slug}
                         onSuccess={() => setCurrentView("success")} // Pass the setCurrentDisplay function as a prop
                         channel={channel}
                         balance={balance}
                         amount_paid={channel === "invoice" ? paymentDetails.amount_paid : null}
                       />
                      ) : (
                        <div className="card-form-div animate-pulse flex flex-col items-center">
                          <div className="mb-4 h-10 w-[100%] bg-gray-300 rounded"></div>
                          <div className="my-5 h-[16em] w-[80%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-[2em] w-[100%] bg-gray-300 rounded"></div>
                          <div className="mt-2 h-6 w-40 bg-gray-300 rounded"></div>
                        </div>
                      )}
                    </div>
                  ) : currentDisplay === "card" ? (
                    <CardPayment
                      payer={payerDetails}
                      payment={paymentDetails}
                      amount={formattedAmount}
                      slug={slug}
                    />
                  ) : (
                    <USSDPayment
                       bankList={bankList} 
                       amount={formattedAmount}
                       slug={slug}
                       payerDetails={payerDetails}
                       channel={channel}
                       paymentDetails={paymentDetails}
                       amount_paid={channel === "invoice" ? paymentDetails.amount_paid : null}
                       
                       />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={toggleModalDisplay}
        className="modal-cancel sm:block hidden"
      >
        <img src={CancelIcon} alt="cancel" />
      </div>
    </AntdModal>
  );
};

export default PaymentModal;
