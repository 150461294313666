// import { motion } from 'framer-motion';

// const AnimatedSequence = () => {
//   return (
//     <motion.div
//       className="flex items-center justify-center "
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//     >
//       <motion.svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="200"
//         height="200"
//         viewBox="0 0 200 200"
//       >
//         {/* Expanding Circle */}
//         <motion.circle
//           cx="100"
//           cy="100"
//           r="50"
//           fill="none"
//           stroke="#274B85"
//           strokeWidth="10"
//           initial={{ scale: 0, opacity: 0 }}
//           animate={{
//             scale: [1, 1.2, 1],
//             opacity: [0.5, 1, 1],
//             stroke: ["#274B85", "#3F88C5", "#274B85"],
//           }}
//           transition={{
//             duration: 4,
//             repeat: Infinity,
//             ease: "easeInOut",
//           }}
//         />

//         {/* Rotating Dashed Circle */}
//         <motion.circle
//           cx="100"
//           cy="100"
//           r="70"
//           fill="transparent"
//           stroke="#4DA6FF"
//           strokeWidth="4"
//           strokeDasharray="10 20"
//           initial={{ rotate: 0 }}
//           animate={{ rotate: 360 }}
//           transition={{
//             duration: 2,
//             repeat: Infinity,
//             ease: "linear",
//           }}
//         />

//         {/* Morphing Star */}
//         {/* <motion.path
//           d="M100 50 L115 85 L155 85 L125 115 L140 150 L100 130 L60 150 L75 115 L45 85 L85 85 Z"
//           fill="#FFC107"
//           initial={{ scale: 0, rotate: 0, opacity: 0 }}
//           animate={{
//             scale: [0, 1.2, 1],
//             rotate: [0, 45, 0],
//             opacity: 1,
//           }}
//           transition={{
//             duration: 6,
//             repeat: Infinity,
//             repeatType: "reverse",
//             ease: "easeInOut",
//           }}
//         /> */}

//         {/* Circular Stroke Path */}
//         {/* <motion.circle
//           cx="100"
//           cy="100"
//           r="90"
//           fill="none"
//           stroke="#FFF"
//           strokeWidth="2"
//           strokeDasharray="565"
//           strokeDashoffset="565"
//           initial={{ strokeDashoffset: 565 }}
//           animate={{ strokeDashoffset: 0 }}
//           transition={{
//             duration: 4,
//             repeat: Infinity,
//             ease: "easeInOut",
//           }}
//         /> */}

//         {/* Checkmark */}
//         <motion.path
//           d="M70 100 L90 120 L140 80"
//           stroke="#3F88C5"
//           strokeWidth="10"
//           fill="transparent"
//           initial={{ pathLength: 0, opacity: 0 }}
//           animate={{
//             pathLength: [0, 1],
//             opacity: 1,
//           }}
//           transition={{
//             delay: 4,
//             duration: 1.5,
//             ease: "easeInOut",
//           }}
//         />
//       </motion.svg>
//     </motion.div>
//   );
// };

// export default AnimatedSequence;

// import { motion } from "framer-motion";

// const AnimatedSequence = () => {
//   return (
//     <motion.div
//       className="flex items-center justify-center h-screen bg-gray-900"
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//     >
//       <motion.svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="200"
//         height="200"
//         viewBox="0 0 200 200"
//       >
//         {/* Spinning Outline Circle */}
//         <motion.circle
//           cx="100"
//           cy="100"
//           r="80"
//           fill="none"
//           stroke="#4DA6FF"
//           strokeWidth="6"
//           strokeDasharray="500"
//           strokeDashoffset="500"
//           initial={{ rotate: 0 }}
//           animate={{
//             strokeDashoffset: 0,
//             rotate: 360,
//           }}
//           transition={{
//             duration: 3, // Slower animation
//             ease: "linear",
//           }}
//         />

//         {/* Morphing Circle to Outline */}
//         <motion.path
//           d="M100,20 A80,80 0 1,1 99.99,20 Z" // Circular outline path
//           stroke="#00FF7F"
//           strokeWidth="6"
//           fill="transparent"
//           strokeLinecap="round"
//           initial={{ pathLength: 0, opacity: 0 }}
//           animate={{
//             pathLength: [0, 1],
//             opacity: 1,
//           }}
//           transition={{
//             duration: 2.5,
//             delay: 3, // Start after spinning circle
//             ease: "easeInOut",
//           }}
//         />

//         {/* Centered Checkmark */}
//         <motion.path
//           d="M85 100 L95 115 L120 90" // Smaller, centered checkmark
//           stroke="#00FF7F"
//           strokeWidth="6"
//           fill="transparent"
//           strokeLinecap="round" // Rounded corners
//           strokeLinejoin="round"
//           initial={{ pathLength: 0, opacity: 0 }}
//           animate={{
//             pathLength: 1,
//             opacity: 1,
//           }}
//           transition={{
//             duration: 1.5,
//             delay: 5.5, // After morphing completes
//             ease: "easeOut",
//           }}
//         />
//       </motion.svg>
//     </motion.div>
//   );
// };

// export default AnimatedSequence;

import { motion } from "framer-motion";

const AnimatedSequence = () => {
  return (
    <motion.div
      className="flex items-center justify-center md:mb-5 h-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="150"
        height="150"
        viewBox="0 0 200 200"
      >
        {/* Smaller Circle */}
        <motion.circle
          cx="100"
          cy="100"
          r="60" // Reduced circle radius
          fill="none"
          stroke="#4DA6FF"
          strokeWidth="6"
          strokeDasharray="377" // Adjusted for smaller size
          strokeDashoffset="377"
          initial={{ rotate: 0 }}
          animate={{
            strokeDashoffset: 0,
            rotate: 360,
          }}
          transition={{
            duration: 3, // Slower animation
            ease: "linear",
          }}
        />

        {/* Morphing Circle Outline */}
        <motion.path
          d="M100,40 A60,60 0 1,1 99.99,40 Z" // Adjusted for smaller circle
          stroke="#008000"
          strokeWidth="6"
          fill="transparent"
          strokeLinecap="round"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{
            pathLength: [0, 1],
            opacity: 1,
          }}
          transition={{
            duration: 2.5,
            delay: 3, // Start after spinning animation
            ease: "easeInOut",
          }}
        />

        {/* Larger Checkmark */}
        <motion.path
          d="M70 100 L90 120 L130 80" // Enlarged and repositioned checkmark
          stroke="#008000"
          strokeWidth="8" // Thicker for better visibility
          fill="transparent"
          strokeLinecap="round" // Rounded edges for smoothness
          strokeLinejoin="round"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{
            pathLength: 1,
            opacity: 1,
          }}
          transition={{
            duration: 1.5,
            delay: 5.5, // After morphing circle completes
            ease: "easeOut",
          }}
        />
      </motion.svg>
    </motion.div>
  );
};

export default AnimatedSequence;
