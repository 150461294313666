/* global PayWithProvidus */

import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Checkbox } from "antd";

import CopyImg from "../../assets/images/copy.svg";
import { Link, useNavigate } from "react-router-dom";
import Flag from "../../assets/images/flag.svg";

import { Controller, useForm } from "react-hook-form";

import { Input, Modal } from "antd";
import Loader from "../Loader/Loader";

import { TextField } from "@mui/material";
import EmailVerification from "./email";
import TransactionStatus from "./status";


const CardPayment = ({ payer, payment, amount, slug }) => {
  const Navigate = useNavigate();
  const { handleSubmit, control } = useForm({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const {
    handleSubmit: handleCardDetailsForm,
    control: controlCardDetailsForm,
  } = useForm({});

  const [currentView, setCurrentView] = useState("default");

  const handleFormSubmit = () => {
    Navigate("/payment_method");
  };

  const toggleModalDisplay = () => {};

  const reRoutePage = () => {
    setCurrentView("status");
  };

  const selectCardType = () => {
    setCurrentView("redirect");
    // setCurrentView("details");
  };

  const collectCardDetail = () => {
    setCurrentView("verification");
  };


//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://providuspayupload.s3.eu-west2.amazonaws.com/pay.js';
//     script.onload = () => {
//       window.payWithProvidusReady = true;
//     };
//     document.head.appendChild(script);
//   }, []);
  
  function payWithProvidus(e) {
    e.preventDefault();
    const handler = PayWithProvidus.setup({
      clientId: "Your Client ID",
      publicKey: "Your Public Key",
      customerName: payer.name,
      amount: amount,
      email: payer.email,
      currency: payment.currency,
      reference: payer.id,
      description: payment.description,
      redirectURL: "http://app.payscribe.ng/",
      cancelURL: `http://localhost:5001/${slug}`,
      onClose: function () {
        alert("Close pressed");
      },
      callback: function (response) {
        alert("Callback pressed");
      },
    });
  
    handler.openIframe();
  }
  

  // if (loading) return <Loader />;
  // if (error) return <div>Error: {error}</div>;

  return (
    <div className="card-form-div">
    <Helmet>
      {/* <script src="https://providuspayupload.s3.eu-west2.amazonaws.com/pay.js"></script> */}
    </Helmet>
        {/* <script defer="defer" src="https://providuspayupload.s3.eu-west2.amazonaws.com/pay.js"></script> */}
      {currentView === "default" ? (
        <React.Fragment>
          <div className="payment-method">
            <div className="center">
              <h3>Select Card Type</h3>
            </div>
            <form id="payment-form" onSubmit={handleSubmit(selectCardType)}>
              <div className="payment-method-outline mt_5">
                <div className="single-payment-method active">
                  <div className="grid-flex">
                    <div>
                      <div className="flex-div">
                        <img src={Flag} alt="flag" />
                        <h5>Nigeria</h5>
                      </div>
                    </div>
                    <div className=""></div>
                  </div>
                </div>
              </div>
              <div className="mt_20">
                <button className="text-white bg-primary p-4 rounded-xl w-full my-4 ">
                  Continue
                </button>
              </div>
            </form>
          </div>
        </React.Fragment>
      ) : currentView === "details" ? (
        <React.Fragment>
          <div className="center">
            <h3>Enter your card details to pay</h3>
          </div>
          <form onSubmit={handleCardDetailsForm(collectCardDetail)}>
            <div className="payment-div-body">
              <div className="form-group mt_5">
                <label>Card Number</label>
                <Controller
                  control={controlCardDetailsForm}
                  name="card_number"
                  render={({ field }) => (
                    // <TextField id="filled-basic" {...field} placeholder="0000 0000 0000 0000" label="Card Number" variant="filled" />
                    <Input
                      type="text"
                      placeholder="0000 0000 0000 0000"
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="grid-3 mt_5">
                <div className="form-group">
                  <label>Expiry Date</label>
                  <Controller
                    control={controlCardDetailsForm}
                    name="expiry"
                    render={({ field }) => (
                      // <TextField id="filled-basic" {...field} placeholder="07/2026" label="Expiry Date" variant="filled" />
                      <Input type="text" placeholder="07/2026" {...field} />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>CVV</label>
                  <Controller
                    control={controlCardDetailsForm}
                    name="cvv"
                    render={({ field }) => (
                      // <TextField id="filled-basic" label="CVV" placeholder="000" variant="filled" {...field} />
                      <Input type="text" placeholder="000" {...field} />
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Pin</label>
                  <Controller
                    control={controlCardDetailsForm}
                    name="pin"
                    render={({ field }) => (
                      // <TextField id="filled-basic" {...field} placeholder="0000" label="Pin" variant="filled" />
                      <Input type="text" placeholder="0000" {...field} />
                    )}
                  />
                </div>
              </div>
              <div className="button-cover">
                <button
                  id="myBtn"
                  onClick={toggleModalDisplay}
                  className="text-white bg-primary p-4 rounded-xl w-full my-4 "
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        </React.Fragment>
      ) : currentView === "verification" ? (
        <EmailVerification onComplete={reRoutePage} />
      ) : currentView === "redirect" ? (
        <div>
          <div className="flex items-center h-full justify-center">
            <div>
              <p className="text-center my-5 font-semibold text-2xl">
                You will be redirected
              </p>
              <div className="flex justify-center">
                <button
                  className="bg-primary text-white p-4 rounded-lg hover:text-white my-4"
                  onClick={payWithProvidus}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TransactionStatus />
      )}
    </div>
  );
};
export default CardPayment;
