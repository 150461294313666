import React from "react";


const MaskedEmail = ({ email }) => {
  const maskedEmail = email
    .split("@")
    .map((part, index) => {
      if (index === 0) {
        return part
          .split("")
          .map((char, i) => (i < 2 || i >= part.length - 2 || part.length <= 4 ? char : "*"))
          .join("");
      }
      return part;
    })
    .join("@");

  return <span>{maskedEmail}</span>;
};

export default MaskedEmail;