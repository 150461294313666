import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { LuClipboardCheck, LuClipboardList } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../Styles/main.scss";
import AnimatedSequence from "../animatedloadersequence";
import Pending from "../Pending";
import Wait from "../../assets/wait.svg";

/**
 * A React component that displays a payment form to the user. The component
 * accepts props for the bank details, amount to be paid, and the slug of the
 * payment link. It handles the payment process and displays the result to
 * the user.
 *
 * @param {Object} bankDetails - The bank details provided by the user.
 * @param {number} amount - The amount to be paid.
 * @param {string} expiryAt - The timestamp of when the account number expires.
 * @param {string} payerId - The id of the payer.
 * @param {function} onSuccess - The callback function to be called when the
 * payment is successful.
 * @param {string} slug - The slug of the payment link.
 * @param {string} channel - The type of payment, either "payment_link" or "invoice".
 * @param {number} balance - The amount already paid.
 * @param {number} amount_paid - The amount already paid.
 */
const BankPayment = ({
  bankDetails,
  amount,
  expiryAt,
  payerId,
  onSuccess,
  slug,
  channel,
  balance,
  amount_paid,
}) => {
  const [remainingTime, setRemainingTime] = useState(null);
  const [currentView, setCurrentView] = useState("default");
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading] = useState(false); // Initially set to true
  const [setManualCopyMode] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [isSourceActive, setIsSourceActive] = useState(false);
  const eventSourceRef = useRef(null);
  const timeoutRef = useRef(null);
  const [timeoutTriggered, setTimeoutTriggered] = useState(false);

  const checkPaymentStatus = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (!bankDetails || !slug) {
      console.error("Missing account number or slug");
      return;
    }

    closeEventSource();

    console.log("Initializing new EventSource...");
    eventSourceRef.current = new EventSource(
      `https://api.payscribe.ng/api/v1/links/${slug}/${bankDetails.account_number}/verify-payment`
    );

    setIsSourceActive(true);
    eventSourceRef.current.onmessage = (event) => {
      const paymentData = JSON.parse(event.data);
      setPaymentStatus(paymentData);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      console.log("Payment status:", paymentData.status);

      switch (paymentData.status) {
        case "pending":
          setCurrentView("loading");
          break;

        case "unmatch":
          if (paymentData.total_amount_paid > amount_paid) {
            if(amount - paymentStatus.total_amount_paid === 0){
              console.log("This is the difference ", amount - paymentStatus.total_amount_paid )
            checkPaymentStatus()
              setCurrentView("balance");
              console.log("one")
            }
            else{
            setCurrentView("success");
            console.log("two")
          }
            setTimeoutTriggered(false);
          } else {
            setCurrentView("loading");
          }
          break;

        case "closed":
          setCurrentView("balance");
          setTimeoutTriggered(false);

          closeEventSource();
          break;

        case "success":
          setCurrentView("success");
          setTimeoutTriggered(false);

          // closeEventSource();
          break;

        default:
          if (!timeoutTriggered) {
            console.log("Setting timeout for waiting...");
            triggerWaitingTimeout();
          }
      }
    };

    const triggerWaitingTimeout = () => {
      setTimeoutTriggered(true);
      timeoutRef.current = setTimeout(() => {
        console.log("Timeout triggered - switching to waiting view");
        setCurrentView("waiting");
        setTimeoutTriggered(false);
      }, 120000);
    };

    eventSourceRef.current.onerror = () => {
      console.error("Error with EventSource");
      closeEventSource();
    };
  };

  const closeEventSource = () => {
    if (eventSourceRef.current) {
      console.log("Closing EventSource...");

      switch(paymentStatus.status){
        case "pending":
      setCurrentView("waiting");
      break;
      case "success":
        setCurrentView("success");
        break;
      case "unmatch":
        if(paymentStatus.total_amount_paid > amount){
          if(amount - paymentStatus.total_amount_paid === 0){
            setCurrentView("balance");
            console.log("one")
          }
          else{
          setCurrentView("success");
          console.log("twoc")
        }
        }else{
          setCurrentView("balance");
            console.log("three")
          }break;
        default:
          setCurrentView("success");
          console.log("four")
        }
      eventSourceRef.current.close();
      eventSourceRef.current = null;
      setIsSourceActive(false);
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      closeEventSource(); // Cleanup on unmount
    };
  }, []);

  console.log("Current view:", currentView);
  // if (amount - paymentStatus.total_amount_paid === 0){
  //   setCurrentView("success");
  // }

  const handleCopy = () => {
    if (bankDetails?.account_number) {
      if (
        navigator.clipboard &&
        typeof navigator.clipboard.writeText === "function"
      ) {
        // Try Clipboard API
        navigator.clipboard
          .writeText(bankDetails.account_number)
          .then(() => {
            setCopySuccess(true); // Show success message
            setTimeout(() => setCopySuccess(false), 4000);
          })
          .catch(() => {
            // If Clipboard API fails, try the fallback
            fallbackCopyTextToClipboard(bankDetails.account_number);
          });
      } else {
        // Clipboard API not supported, use fallback
        fallbackCopyTextToClipboard(bankDetails.account_number);
      }
    }
  };

  // Fallback using a <textarea> for unsupported browsers/devices
  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Position it off-screen
    textArea.style.position = "fixed";
    textArea.style.top = "-9999px";
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    const successful = document.execCommand("copy");

    if (successful) {
      setCopySuccess(true); // Show success message
      setTimeout(() => setCopySuccess(false), 4000);
    } else {
      // If copy fails, inform the user
      alert(
        "Copy feature is not supported on your device. Please copy manually."
      );
      setManualCopyMode(true); // Enable manual copy mode
    }

    // Remove the <textarea>
    document.body.removeChild(textArea);
  };
  const expiryTime = new Date(expiryAt).getTime();
  const currentTime = new Date().getTime();
  useEffect(() => {
    const startCountdown = () => {
      const expiryTime = new Date(expiryAt).getTime();
      const currentTime = new Date().getTime();

      console.log(
        "Time difference in seconds: ",
        (expiryTime - currentTime) / 1000
      );

      const savedRemainingTime = parseInt(
        localStorage.getItem(`remainingTime_${payerId}`),
        10
      );
      const lastChecked =
        parseInt(localStorage.getItem(`lastChecked_${payerId}`), 10) ||
        Math.floor(currentTime / 1000); // Use current time if not found

      let timeLeft;
      if (savedRemainingTime) {
        const timePassed = Math.floor(currentTime / 1000) - lastChecked;
        timeLeft = savedRemainingTime - timePassed;
      } else {
        timeLeft = Math.floor((expiryTime - currentTime) / 1000);
      }

      if (timeLeft > 0) {
        setRemainingTime(timeLeft);
        localStorage.setItem(`remainingTime_${payerId}`, timeLeft);
        localStorage.setItem(
          `lastChecked_${payerId}`,
          Math.floor(currentTime / 1000)
        ); // Store last checked in seconds
      } else {
        setRemainingTime(0);
      }
    };

    startCountdown();

    const interval = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 0) {
          const newTime = prevTime - 1;
          localStorage.setItem(`remainingTime_${payerId}`, newTime);
          localStorage.setItem(
            `lastChecked_${payerId}`,
            Math.floor(new Date().getTime() / 1000)
          ); // Save in seconds
          // localStorage.clear();
          return newTime;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryAt, payerId]);

  // Format time into minutes and seconds
  const formatTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return "00:00";
    }
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const currencySymbols = {
    usd: "$",
    ngn: "₦",
  };

  const getCurrencySymbol = (currency) => {
    return currencySymbols[currency] || "#";
  };

  // alert((expiryTime - currentTime) / 3600);

  // {(expiryTime - currentTime) <= 0 && (
  //   <div className="bg-red-400">
  //     <p>The account number is expired</p>

  //   </div>
  // )}

  if ((expiryTime - currentTime) <= 0 && channel === "payment_link") return (
  <div className=" h-full card-form-div">
    <div className="p-4">    <div className="m-4">
    <p className="text-center text-2xl my-5">The account number is expired</p>
    </div>
    <div className="button-cover">
              <button
                id="myBtn"
                //  onClick={checkPaymentStatus}
                onClick={() => {
                  if (!isSourceActive) {
                    checkPaymentStatus();
                  } else {
                    console.log(
                      "EventSource is already active. No new instance created."
                    );
                  }
                }}
                className="bg-primary p-4 text-white w-full rounded-xl font-medium"
              >
                Get a new account number
              </button>
            </div>
            </div>

    </div>
  )


  if (isLoading) return
  <div className="card-form-div my-5 flex flex-col justify-center items-center">
    <Skeleton width={1400} height={80} className="mx-3 mb-4" />
    <Skeleton width={1200} height={190} className="mx-3 my-5" />
    <Skeleton width={250} height={40} className="mx-3 mt-2" />
    <Skeleton width={150} height={20} className="mx-3 mt-2" />
  </div>;

  return (
    <div className="card-form-div relative font-sans">
      {currentView === "default" && (
        <div>
          <div className="center">
            <h3>
              Transfer <span className="text-3xl ">{getCurrencySymbol(bankDetails.currency.toLowerCase())}
              {channel === "invoice" ? balance : amount} </span>{" "} to the following
              account
            </h3>
          </div>
          <div className="payment-div-body">
            <div className="payment-sect-summary"></div>
            <div className="mt_5">
              <div className="bank-detail-box m-4 md:my-0 my-10">
                <div className="md:my-0 my-10">
                  <h5 className="text-2xl m-5">
                    {bankDetails?.bank_name || <Skeleton width={100} />}
                  </h5>
                  <div className="account-bar m-5">
                    <div className=" text-6xl flex items-center">
                      <h3>
                        {bankDetails?.account_number || (
                          <Skeleton width={120} />
                        )}
                      </h3>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={handleCopy}
                        className={`copy-icon ${
                          copySuccess ? "copied" : ""
                        } text-4xl`}
                      >
                        {copySuccess ? (
                          <LuClipboardCheck />
                        ) : (
                          <LuClipboardList />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <p className="leading-8 m-10 p-1">
                  Account Name:{" "}
                  <b>{bankDetails?.account_name || <Skeleton width={150} />}</b>
                  <br />
                  {channel === "payment_link" && (
                    <span>
                      You can only make a single exact payment to this account
                      number. <br />
                      Account expires in{" "}
                      <span className="font-semibold">
                        {remainingTime !== null ? (
                          formatTime(remainingTime)
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </span>{" "}.
                    </span>
                  )}
                </p>
                {copySuccess && (
                  <p className="copy-feedback">Account Number Copied!</p>
                )}
              </div>
            </div>
            <div className="button-cover">
              <button
                id="myBtn"
                //  onClick={checkPaymentStatus}
                onClick={() => {
                  if (!isSourceActive) {
                    checkPaymentStatus();
                  } else {
                    console.log(
                      "EventSource is already active. No new instance created."
                    );
                  }
                }}
                className="bg-primary p-4 text-white w-full rounded-xl font-medium"
              >
                I have paid
              </button>
            </div>
          </div>
        </div>
      )}

      {currentView === "loading" && (
        <React.Fragment>
          <div className="center md:h-auto h-screen block items-center">
            <div>
              <Pending />
              <h3>
                We are checking your payment. This may take a few minutes.
              </h3>
            </div>
            <div className="payment-div-body">
              <div className="my-4">
                <h4>You will be notified when your payment is confirmed.</h4>
              </div>
              <div>
                <button
                  className="bg-primary p-4 text-white w-full rounded-xl font-medium"
                  onClick={() => {
                    closeEventSource();
                    setCurrentView("default");
                  }}
                >
                  View account details
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {currentView === "balance" && (
        <>
          <div className="flex flex-col items-center transform md:my-0 my-10 justify-end text-2xl w-full  font-sans md:h-auto h-auto">
            <p className="text-center font-semibold text-amber-800 md:mb-10 mb-10">
              Payment Incomplete!
            </p>
            <div className="mb-16 flex justify-center border border-dashed border-primary/50 rounded-xl p-8 w-full">
              <div className="justify-self-center flex justify-between w-full  flex-col p-3">
                <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                  <span className="w-full">Total amount paid:</span>{" "}
                  <span className="text-primary tracking-wide flex justify-center">
                    {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                    {paymentStatus.total_amount_paid}
                  </span>
                </div>
                <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                  <span className="w-full">Amount paid now:</span>{" "}
                  <span className="text-primary tracking-wide flex justify-center">
                    {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                    {paymentStatus.amount}
                  </span>
                </div>
                <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                  <span className="w-full">Amount to be paid:</span>{" "}
                  <span className="text-primary tracking-wide flex justify-center">
                    {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                    {amount}
                  </span>
                </div>
                <div className="flex justify-items-start grid-cols-[4fr_1.5fr] md:m-2 m-5 gap-5 md:py-1 py-4 font-medium justify-self-start my-1">
                  <span className="w-full">Amount remaining: </span>
                  <span className="text-primary tracking-wide flex justify-center">
                    {getCurrencySymbol(paymentStatus.currency.toLowerCase())}
                    {amount - paymentStatus.total_amount_paid}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <button
                className="bg-primary p-4 text-white w-full rounded-xl font-medium"
                onClick={() => {
                  setCurrentView("default");
                  closeEventSource();
                }}
              >
                View account details
              </button>
            </div>
          </div>
        </>
      )}

      {currentView === "success" && (
        <React.Fragment>
          <div className="flex justify-start flex-col items-center transform md:-translate-y-3 translate-y-[6em]  font-sans md:h-auto h-screen">
            <AnimatedSequence className="md:scale-[40%] scale-[60%]" />
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 8, duration: 1 }}
              className="text-2xl text-center font-semibold flex justify-center"
            >
              Payment Successful
            </motion.span>

            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 10, duration: 1 }}
              onAnimationComplete={() => {
                setTimeout(() => {
                  window.location.href = `/${
                    channel === "payment_link" ? "pay" : "inv"
                  }/${slug}`;
                }, 10000);
              }}
              className="text-xl text-center font-semibold flex justify-center my-5"
            >
              You will be redirected away in a few seconds...
            </motion.span>
          </div>
        </React.Fragment>
      )}

      {currentView === "waiting" && (
        <>
          <div className="relative">
            <div className="absolute flex items-center justify-start flex-col bottom-1/4">
              <div className="z-10 text-center text-2xl font-semibold h-full">
                You do not have to wait for so long, you will be notified when
                the payment has been made...
              </div>
              <div className="button-cover z-10">
                <button
                  id="myBtn"
                  onClick={() => {
                    console.log("Keep waiting button clicked");

                    // Clear any existing timeout
                    if (timeoutRef.current) {
                      clearTimeout(timeoutRef.current);
                      timeoutRef.current = null;
                    }

                    // Change the view to "loading"
                    setCurrentView("loading");

                    // Re-initiate the EventSource if inactive
                    if (!isSourceActive) {
                      checkPaymentStatus();
                    } else {
                      console.log(
                        "EventSource is already active. No new instance created."
                      );
                    }
                  }}
                  className="bg-primary p-4 text-white w-full rounded-xl font-semibold"
                >
                  Keep waiting
                </button>
              </div>
            </div>

            <div className=" top-0 left-0 opacity-[15%] ">
              <img src={Wait} className="" alt="" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BankPayment;
