import React from "react";

interface Props {
  phone: number;
}

const MaskedPhone = ({ phone }) => {
  const maskedPhone = phone
    .split("@")
    .map((part, index) => {
      if (index === 0) {
        return part
          .split("")
          .map((char, i) => (i < 0 || i >= part.length - 4 ? char : "*"))
          .join("");
      }
      return part;
    })
    .join("@");

  return <span>{maskedPhone}</span>;
};

export default MaskedPhone;